<!--  -->
<template>
  <div :class="myslh=='elder'?'old':''">
    <div class="nr">
      <dl class="content_twogai"
          v-for="(item) in list"
          :key="item.id">
        <td>
          <img :src="item.img"
               alt="" />
          <div class="people_box">
            <div class="name">
              <p>{{item.name}}</p>
              <p class="jj_txt">椒江区信访代办</p>
            </div>
            <p class="team_txt">{{item.team_name}}</p>
          </div>
        </td>
        <dd>
          {{item.introduction}}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Image as VanImage } from "vant";
Vue.use(VanImage);
import { Button } from "vant";
Vue.use(Button);
export default {
  data () {
    return {
      list: [],
      myslh: sessionStorage.getItem('getslh'),
    };
  },
  mounted () {
    let that = this;
    this.$get("/front/agent_team/list")
      .then(function (res) {
        res.list.forEach((element) => {
          element.img = that.$url + element.img;
        });
        that.list = res.list;
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>
<style scoped>
body {
  background-color: whitesmoke;
}
.nr {
  padding: 0;
}
.content_twogai {
  padding: 10px 0 0;
  overflow: hidden;
  /* background-color: white; */
  /* border-bottom: 10px solid whitesmoke; */
  background: linear-gradient(to top, #6fb4e5, #b8daf2, #fff);
  border: none;
  margin: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px 3px #ebebeb;
  border-radius: 5px;
}

.content_twogai td {
  overflow: hidden;
  position: relative;
  display: block;
  padding: 0 15px;
}

.content_twogai td img {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  float: left;
}
.people_box {
  float: left;
  width: calc(100% - 50px);
  height: 50px;
  padding-left: 14px;
  box-sizing: border-box;
  /* line-height: 50px; */
}

.people_box .name {
  font-size: 21px;
  font-weight: 600;
  /* height: 25px; */
  overflow: hidden;
  float: left;
  position: relative;
  top: -2px;
}
.jj_txt {
  font-size: 13px;
  color: #9c9c9c;
  font-weight: 500;
  margin-top: 3px;
}
.people_box .team_txt {
  color: #9c9c9c;
  margin-top: 5px;
  float: right;
  border: 1px solid #47b5e6;
  margin-left: 10px;
  padding: 5px 15px;
  font-size: 14px;
  color: white;
  background-color: #47b5e6;
  border-radius: 5px;
}

.van-button {
  position: absolute;
  bottom: 5px;
  right: 15px;
  width: 90px;
  height: 35px;
  border-radius: 5px;
}

.content_twogai dd {
  margin-top: 10px;
  padding: 2px 15px 7px;
  color: #4d4d4d;
  /* font-weight: 600; */
}

.old dd {
  font-size: 18px !important;
}

.old .people_box {
  padding-left: 5px !important;
}

.old .people_box .team_txt {
  font-size: 16px !important;
  margin-left: 0;
  padding: 5px 10px;
}

.old .jj_txt {
  font-size: 16px !important;
  margin-top: 0;
}
</style>